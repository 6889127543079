.App {
  text-align: center;
  background-color: #dbd0d0;
  font-family: 'Open Sans', sans-serif;
}

.hiddenButton{
  visibility: hidden;
}

.navLabel {
  padding: 0px 30px 10px 30px;
  margin: 0px 30px 30px 30px;
  color: white;
}

.navLabel#selected {
  border-bottom: 2px solid white;
}

.footer {
  background-color: #252229;
  color: #cbc0c0;
  height: 55px;
  width: 100%;
  margin: 20px 0 0 0;
  box-sizing: border-box;
  padding: 5px;
}

.footer-text {
  font-size: 0.8em;
	float: left;
  margin-left: 8px;
}

.footer-icon-containers {
	float: right;
  font-size: 25px;
  margin: 8px 8px 0 0;
}

.footer-icon {
  margin-left: 20px;
}

.hidden-anchor, .hidden-anchor:visited, .hidden-anchor:hover, .hidden-anchor:active {
  color: inherit;
}

@media only screen and (min-width: 500px) {
  .footer {
    height: 70px;
    width: 100%;
    margin: 20px 0 0 0;
    box-sizing: border-box;
    padding: 5px;
  }
  
  .footer-text {
    font-size: 1.2em;
    float: left;
    margin-left: 30px;
  }
  
  .footer-icon-containers {
    float: right;
    font-size: 40px;
    margin: 10px 30px 0 0;
  }
  
  .footer-icon {
    margin-left: 20px;
  }
}
