.MainAboutMeDiv {
    color: #DDD;
    margin: 0;
    background-color: #252229;
    padding: 0px 10px 40px 10px;
}
.AboutMeTextDiv {
    margin: 0px 8px 0px 8px;
    padding-bottom: 30px;
    color: #DDD;
    font-size: 1.1em;
}
h3 {
    margin: 0;
    padding: 40px;
    font-size: 2em;
}
@media only screen and (min-width: 500px) {
    .AboutMeTextDiv {
        margin: 0px 80px 0px 80px;
        color: #DDD;
        font-size: 1.5em;
    }
  }